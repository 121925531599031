import { createTheme } from "@mui/material/styles"

const theme = createTheme({
    palette: {
        primary: {
            main: "#571D8D",
            light: "#8050B8",
            veryLight: "#F2F4FB",
            dark: "#391766",
            veryDark: "#1A0133"
        },
        secondary: {
            main: "#ADD656",
            light: "#E7FD96",
            dark: "#7B9738"
        },
        text: {
            main: "#303A3D"
        },
        common: {
            background1: "#F1ECD1",
            background2: "#556A6A",
            red: "#CA3F67",
            black: "#020201",
            offBlack: "#2A363B",
            offWhite: "#F5F0EB",
            outOfStock: "#9E9E9E",
            disabledGrey: "#bdbdbd"
        },
        error: {
            main: "#FF6161"
        },
        success: {
            main: "#96A608"
        },
    },
    typography: {
        h1: {
            fontFamily: "Barlow",
            fontSize: "1.25rem",
            fontWeight: 500,
            color: "#fff"
        },
        h2: {
            fontFamily: "Barlow",
            fontSize: "1.25rem",
            letterSpacing: "1px",
            fontWeight: 400,
            color: "#fff"
        },
        h3: {
            fontFamily: "Barlow",
            fontSize: "1.1rem",
            letterSpacing: "1px",
            fontWeight: 400,
            color: "#fff"
        },
        body1: {
            fontFamily: "Barlow",
            fontSize: "1rem",
            letterSpacing: "0px",
            fontWeight: 300,
            color: "#391766"
        },
    },
    overrides: {}
})

export default theme