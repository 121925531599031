import RootWrapper from "./src/components/ui/root-wrapper"

import "./global.css"
import "@fontsource/galdeano/400.css"
import "@fontsource/barlow/200.css"
import "@fontsource/barlow/300.css"
import "@fontsource/barlow/400.css"
import "@fontsource/barlow/600.css"

export const wrapRootElement = RootWrapper
